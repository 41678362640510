import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modern-sass-footer',
  templateUrl: './modern-sass-footer.component.html',
  styleUrls: ['./modern-sass-footer.component.scss']
})
export class ModernSassFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
