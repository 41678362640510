<!--Nav start-->
<header class="saas2 loding-header nav-abs custom-scroll">
  <div class="container">
      <div class="row">
          <div class="col">
            <nav>
              <a href="#" class="d-inline-block m-r-auto">
                  <img src="assets/images/logo/1.png" alt="" class="img-fluid">
              </a>
            <app-menu></app-menu>
          </nav>
          </div>
      </div>
  </div>
</header>
<!--Nav end-->
