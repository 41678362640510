<!--footer start-->
<footer class="saas1 footer2">
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <div class="logo-sec">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">About Us</h3>
                    </div>
                    <div class="footer-contant">
                        <img src="assets/images/logo/3.png" alt="" class="img-fluid footer-logo">
                        <div class="footer-para">
                            <h6 class="text-white para-address">Sassy</h6>
                            <h6 class="text-white para-address">324 Madison Street New york </h6>
                            <h6 class="text-white para-address">City,NY 10001.</h6>
                        </div>
                        <ul class="d-d-flex footer-social social">
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-google" aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">services</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">services</h5>
                    <div>
                        <ul class="footer-lists">
                            <li class=""><a href="#">Portfolio Website</a></li>
                            <li class=""><a href="#">Set up an account</a></li>
                            <li class=""><a href="#">Invoice Creation</a></li>
                            <li class=""><a href="#">Get Our Specials</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">brand</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">brand</h5>
                    <div>
                        <ul class="footer-lists">
                            <li class=""><a href="#">Chat24</a></li>
                            <li class=""><a href="#">Zoom</a></li>
                            <li class=""><a href="#">Alpha Payment</a></li>
                            <li class=""><a href="#">Upwork</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Support</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Support</h5>
                    <div>
                        <ul class="footer-lists">
                            <li class=""><a href="#">For Freelancers</a></li>
                            <li class=""><a href="#">For Companies</a></li>
                            <li class=""><a href="#">For Business</a></li>
                            <li class=""><a href="#">General Help</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--footer end-->
<app-tap-to-top></app-tap-to-top>
