<!-- price table -->
<section id="plan" class="saas1 pricing"  [ngStyle]="{'background-image': 'url(assets/images/saas1/banner-2.jpg)'}">
    <div class="container">
        <div class="col-md-12 text-center">
            <div class="title">
                <img src="assets/images/saas1/title-logo.png" alt="title-logo" class="img-fluid">
                <div class="main-title">
                    <h2 class="text-white">
                        <span class="theme-color">O</span>ur <span class="theme-color">P</span>lan
                    </h2>
                </div>
                <hr>
                <div class="sub-title">
                    <p class="p-padding text-white">Guide to setup and configuration. You can present below a guide and
                        a description of how your system configuration works and add some animated screens.</p>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center p-0">
            <div class="pricing ">
                <div class="pricing__switcher switcher">
                    <div class="switcher__buttons">
                        <div class="switcher__button monthly" [class.switcher__button--enabled]="val" (click)="monthly()">Monthly</div>
                        <div class="switcher__button yearly" [class.switcher__button--enabled]="!val" (click)="monthly()">Yearly <span>20% <sup>off</sup></span></div>
                        <div class="switcher__border"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div id="monthly" class="wrapper-full">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                        <owl-carousel-o [options]="priceCarouselOptions" class="pricing-slider plan-box">
                        <ng-container>
                        <ng-template carouselSlide *ngFor="let data of price">
                            <div class="price-box">
                                <h3 class="price-heading">{{data.heading}}</h3>
                                <img src="assets/images/saas1/plan-box.png" alt="pan-line">
                                <h4 class="no-weight">$<span>{{data.price}}</span>/Month</h4>
                                <ul>
                                    <li><img src="assets/images/saas1/plan-round.png" alt="plan-icon">
                                       {{data.features1}}
                                    </li>
                                    <li><img src="assets/images/saas1/plan-round.png" alt="plan-icon">
                                        {{data.features2}}
                                    </li>
                                    <li><img src="assets/images/saas1/plan-round.png"
                                             alt="plan-icon">
                                        {{data.features3}}
                                    </li>
                                    <li><img src="assets/images/saas1/plan-round.png" alt="plan-icon">{{data.features4}}
                                    </li>
                                    <li>{{data.det1}}</li>
                                    <li>{{data.det2}}</li>
                                    <li>{{data.det3}}</li>
                                </ul>
                                <a class="btn btn-default btn-white">{{data.btn}}</a>
                        </div>
                        </ng-template>
                        </ng-container>
                        </owl-carousel-o>
                    </div>
            </div>
        </div>
    </div>
</section>
<!-- end price table -->
