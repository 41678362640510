import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprice-sass-subscribe',
  templateUrl: './enterprice-sass-subscribe.component.html',
  styleUrls: ['./enterprice-sass-subscribe.component.scss']
})
export class EnterpriceSassSubscribeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  public openLink(): void{
    window.open("https://wa.me/5511937261354?text=ativar+plano+gratuito+lp", "_blank");
  }
}
