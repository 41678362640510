<!--copyright start-->
<div class="saas1 copyright">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="link-horizontal center-text">
                    <ul>
                        <li>
                            <a href="#" class="copyright-text op-text">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="#" class="copyright-text op-text">Terms &amp; Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-6">
                <div>
                    <h6 class="copyright-text text-white text-right op-text">Copyright ©2018 unice by <i
                            class="fa fa-heart" aria-hidden="true"></i>
                        pixelstrap</h6>
                </div>
            </div>
        </div>
    </div>
</div>
<!--copyright end-->
