import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modern-sass-nav',
  templateUrl: './modern-sass-nav.component.html',
  styleUrls: ['./modern-sass-nav.component.scss']
})
export class ModernSassNavComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
